@import 'abstracts';

.material-spinner {
  .mat-mdc-progress-spinner {
    margin: 40px auto 30px;
  }

  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #00be91;
  }
}
