@import 'abstracts';

.terms-and-policy {
  padding: 150px 0 100px;

  @include media-breakpoint-down(lg) {
    padding: 90px 0 70px;
  }

  h1 {
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .content {
    margin-top: 30px;
    @include setBottomGutters(40px);

    h2 {
      font-size: 25px;
      font-family: $st-font-bold;
      font-weight: 600;
      line-height: 1.4;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }

    .description {
      margin-top: 10px;
      @include setBottomGutters(15px);

      .text {
        font-family: $st-font-regular;
        color: $st-charcoal-2;
        font-weight: 400;
        line-height: 1.4;

        &-ident {
          text-indent: 35px;
        }

        span {
          font-family: $st-font-bold;
          font-weight: 600;

          &.italic {
            font-style: italic;
          }

          &.regular {
            font-weight: 400;
            font-family: $st-font-regular;
          }
        }

        a {
          color: $st-charcoal-2;

          &.link {
            color: $st-blue;
            text-decoration: underline;
          }

          &.underline {
            text-decoration: underline;
          }
        }

        ul {
          margin-left: 10px;

          li:before {
            display: inline-block;
            content: "-";
            width: 1em;
            text-align: center;
          }
        }
      }

      .definitions {
        font-family: $st-font-bold;
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}
