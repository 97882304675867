/* Layouts */
@forward './layouts/utils';
@import './layouts/landing';
@import './layouts/auth-forms';
@import './layouts/terms-and-policy';

/* Base */
@import './base/reset';
@import './base/fonts';
@import './base/typography';

/* Vendors */
@import './vendors/bootstrap';
@import './vendors/font-awesome';
@import './vendors/icons';
@import './vendors/material-theme';
@import './vendors/swiper-slider';
@import './vendors/freakflags';
@import './vendors/star-rating';

/* Components */
@import 'components/swiper';
@import './components/mat-form-field';
@import './components/mat-select';
@import './components/mat-input';
@import './components/mat-button';
@import './components/mat-checkbox';
@import './components/mat-dialog';
@import './components/mat-radio';
@import './components/mat-accordion';
@import './components/mat-spinner';
@import './components/mat-tooltip';
@import './components/mat-calendar';
@import './components/mat-slide';
@import './components/mat-tabs';
@import './components/mat-card';
