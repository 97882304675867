@import 'abstracts';

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-top-salon {
  .swiper-slide {
    width: 255px !important;
  }
}

.swiper-review {
  .swiper-slide {
    @include media-breakpoint-up(sm) {
      width: 350px !important;
    }
  }
}

.swiper-story {
  .swiper-slide {
    @include media-breakpoint-up(sm) {
      width: 368px !important;
    }
  }
}

.swiper-articles {
  .swiper-slide {
    width: 325px !important;

    @include media-breakpoint-up(lg) {
      width: 49% !important;
    }
  }
}

.swiper-products {
  .swiper-slide {
    width: 195px !important;
  }
}

.swiper-salon-articles {
  @include media-breakpoint-up(lg) {
    .swiper-slide {
      width: 426px !important;
    }
  }

  @include media-breakpoint-down(lg) {
    .swiper-slide {
      width: 325px !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .swiper-slide {
      width: 325px !important;
    }
  }
}

.swiper-article-categories {
  .swiper-slide {
    width: 120px !important;
  }
}

.swiper-portfolio {
  .swiper-slide {
    width: 160px !important;
  }
}
