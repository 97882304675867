@import 'abstracts';

.mat-calendar {
  .mat-calendar-body-selected {
    background: $st-primary;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba($st-primary, 0.3);
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {

    &.mat-calendar-body-active {
      &:focus {
        .mat-calendar-body-cell-content:not(.mat-calendar-body-selected).mat-focus-indicator.mat-calendar-body-today {
          background-color: rgba($st-primary, 0.4);
        }
      }
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: rgba($st-primary, 0.38);
  }
}
