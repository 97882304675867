@import 'abstracts';

* {
  box-sizing: border-box;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1360px;
  }
}

h1,
.title-page {
  font-size: 48px;
  line-height: 65px;
  font-family: $st-font-regular;
  color: $st-black-2;
  font-weight: 400;
  letter-spacing: -0.03em;

  span {
    font-weight: 600;
    font-family: $st-font-semi-bold;
  }

  @include media-breakpoint-down(lg) {
    font-size: 36px;
    line-height: 49px;
  }
}

h2 {
  line-height: 1.2;
  font-family: $st-font;
  font-size: 36px;
  font-weight: bold;
  color: $st-medium-jungle-green;

  @include media-breakpoint-down(lg) {
    font-size: 20px;
    line-height: 1.4;
  }
}

.policy-text {
  font-family: $st-font;
  font-size: 0.75rem;
  color: $st-medium-jungle-green;
  font-weight: 400;
  line-height: 1.1875rem;

  .link {
    font-family: $st-font;
    font-size: 0.75rem;
    color: $st-medium-jungle-green;
    font-weight: 400;
    line-height: 1.1875rem;
  }
}

.apps {
  display: flex;
  justify-content: space-between;
  @include setRightGutters(12px);

  @include media-breakpoint-down(lg) {
    margin-right: 0;
    padding-top: 41px;
  }

  @media screen and (max-width: 370px) {
    flex-direction: column;
    justify-content: center;
    @include setRightGutters(0);
    @include setBottomGutters(10px);
  }

  .block {
    background: $st-white;
    border-radius: 12px;

    div {
      width: 145px;
      height: 48px;

      img {
        @include setObjectFit(cover);
        border-radius: 12px;
      }

      @media screen and (max-width: 370px) {
        margin: 0 auto;
      }
    }

    &:hover {
      opacity: 0.8;
      transition: 0.3s ease;
    }

    @include media-breakpoint-between(lg, xl) {
      min-width: 160px;
    }
  }
}

.ql-editor {
  height: unset !important; /* stylelint-disable-line declaration-no-important */

  a {
    color: $st-primary !important;;
  }

  strong {
    font-weight: bold;
    font-family: $st-font-bold;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    letter-spacing: -0.03em;
    font-family: $st-font-semi-bold;
    font-weight: 600;
    margin-bottom: 20px !important;

    span, u, em {
      font-family: $st-font-semi-bold;
      font-weight: 600;
    }
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 16px;
  }

  @include media-breakpoint-down(lg) {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 16px;
    }

    h6 {
      font-size: 14px;
    }
  }

  .ql-align-justify {
    padding-right: 5px;
  }

  .ql-video {
    width: 100%;
    height: 400px;
  }
}

a {
  text-decoration: unset;
}

.is-menu-visible {
  overflow: hidden;
}

.cdk-overlay-dark-backdrop {
  background: rgba($st-black-2, 0.5);
}

.phone-show-hide {
  @include flexCentering;
  justify-content: space-between;
  @include setRightGutters(25px);

  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }

  .phone-item {
    display: flex;
    align-items: center;
    @include setRightGutters(10px);

    .icon {
      background: $st-white-smoke-3;
      border-radius: 50%;
      padding: 12px;

      i {

        font-size: 16px;
        color: $st-charcoal;
      }
    }

    .item {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      font-family: $st-font-semi-bold;
      letter-spacing: -0.02em;
      color: $st-charcoal;

      &-action {
        cursor: pointer;
      }
    }
  }
}

.reviews-total-view {
  display: flex;
  align-items: center;

  .icon-star {
    color: $st-yellow-2;
    font-size: 20px;
    margin-right: 6px;
  }

  .dot {
    @include proportions(3px);
    background: rgba($st-charcoal, 0.5);
    border-radius: 50%;
    margin: 0 4px;
  }

  .number {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    font-family: $st-font-semi-bold;
    color: $st-charcoal;
  }
}

.phone-prefix {
  color: rgba($st-charcoal, 0.5);
}

@media screen and (max-width: 2000px) {
  .extra-desktop {
    display: none;
  }
}

@media screen and (min-width: 2000px) {
  .desktop {
    display: none;
  }

  .extra-desktop {
    display: block;
  }
}

.mobile-reservation {
  @include media-breakpoint-down(sm) {
    app-footer {
      display: none;
    }
  }
}

.listViewMobile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: $st-white-smoke-3;
  border-radius: 8px;

  div {
    width: 50%;
    text-align: center;
    padding: 14px;
    background: $st-white-smoke-3;

    &:nth-child(1) {
      border-radius: 8px 0 0 8px;
    }

    &:nth-child(2) {
      border-radius: 0 8px 8px 0;
    }

    &.active-mobile {
      background: $st-white;
      border-radius: 8px;
      padding: 13px 0;
      border: 1px solid rgba($st-charcoal, 0.1);
    }

    i {
      font-size: 12px;
      color: $st-charcoal;
      @extend .viewIcons;
    }
  }
}

.list-bar {
  &.bar {
    margin: 30px 0;
    background: $st-white-smoke-3;
    border-radius: 12px;
    padding: 10px 13px 10px 24px;
    @include flexCentering;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      margin: 20px 0;
      padding: 10px;
    }

    @media screen and (max-width: 350px) {
      display: block;
      @include setBottomGutters(5px);
    }

    .results {
      font-family: $st-font-semi-bold;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $st-charcoal;
    }

    .sorting {
      display: flex;
      align-items: center;
      @include setRightGutters(20px);

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        font-family: $st-font-regular;
        color: $st-charcoal;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      mat-form-field {
        display: block;
        width: 150px;
      }

      .view {
        display: flex;
        align-items: center;
        @include setRightGutters(5px);

        i {
          font-size: 12px;
          color: $st-charcoal;
          background: #fff;
          border: 1px solid rgba(45, 57, 76, 0.1);
          border-radius: 8px;
          padding: 10px;
          cursor: pointer;

          @extend .viewIcons;
        }
      }
    }
  }
}

.viewIcons {
  &.active {
    color: $st-primary;
  }

  &:hover {
    transition: 0.3s ease;
    opacity: 0.7;
  }
}

.fit-content-overlay-panel {
  width: fit-content!important;
}


.city-selector {
  min-width: calc(100% + 64px);
}

.block-fade {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  &.animated {
    opacity: 1;
    transform: translateY(0);
  }
}

.top-title-page {
  background: $st-white-smoke-3;

  .title {
    text-align: center;
    padding: 60px;

    span {
      font-weight: 600;
      font-family: $st-font-semi-bold;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      line-height: 30px;
      padding: 14px;
    }
  }
}

.list-bottom {
  .text-bottom {
    text-align: center;
    padding: 30px 60px;
    background: $st-white-smoke-3;

    @include media-breakpoint-down(lg) {
      padding: 14px;
    }

    h2 {
      font-size: 20px;
      font-family: $st-font;
      font-weight: 100;
    }
  }
}

.ngx-toast__wrapper {
  z-index: 9999 !important;
}
