@import 'abstracts';

.mat-mdc-dialog-container .mdc-dialog__surface {
  background: $st-black;
  border-radius: unset !important;
}

.social-dialog,
.request-call-dialog,
.video-popup,
.auth-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 40px;

    @include media-breakpoint-down(sm) {
      padding: 40px 15px 20px;
    }
  }
}

.social-dialog,
.request-call-dialog,
.qr-codes-dialog,
.video-popup,
.auth-dialog {
  position: relative !important;

  @include media-breakpoint-down(sm) {
    max-width: 100% !important;
    padding: 0 15px;
  }

  .close {
    position: absolute;
    top: 14px;
    right: 4px;

    i {
      color: $st-white;
      background: $st-charcoal;
      border: 2px solid $st-white;
      padding: 11px;
      border-radius: 50%;
      font-size: 10px;
      cursor: pointer;
    }
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: $st-white;
    border: 1px solid rgba($st-charcoal, 0.1);
    border-radius: 12px !important;
    overflow: hidden;
  }
}

.qr-codes-dialog {
  mat-dialog-container {
    background: unset !important;
    padding: 0;
  }
}

.review-dialog,
.services-dialog {
  @include media-breakpoint-down(md) {
    max-width: 100% !important;
    max-height: 100% !important;
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    position: relative !important;

    .close {
      position: absolute;
      top: -8px;
      right: -18px;

      i {
        color: $st-white;
        background: $st-charcoal;
        border: 2px solid $st-white;
        padding: 11px;
        border-radius: 50%;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: $st-white;
    padding: 30px 15px;

    @include media-breakpoint-up(md) {
      background: $st-white;
      border: 1px solid rgba($st-charcoal, 0.1);
      border-radius: 12px !important;
    }
  }

  .top {
    margin-bottom: 20px;
    @include flexCentering;
    justify-content: space-between;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      font-family: $st-font-semi-bold;
      letter-spacing: -0.03em;
      color: $st-charcoal;
      text-transform: capitalize;
    }

    .close-mobile {
      @include flexCentering;
      @include proportions(34px);
      border-radius: 50%;
      background: $st-white-smoke-3;
      margin-right: 5px;
      cursor: pointer;

      i {
        font-size: 12px;
      }
    }
  }
}

.services-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 40px;
  }
}

.auth-dialog {
  @include media-breakpoint-down(md) {
    max-width: 100% !important;
    max-height: 100% !important;
    height: 100%;
    padding: 0;

    .close {
      right: 20px;
      top: 20px;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
      border-radius: 0 !important;
    }
  }
}

.gallery-dialog {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-color: #040507;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 24px;
  }
}
