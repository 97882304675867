@import 'abstracts';

.auth-forms {
  .title {
    font-family: $st-font-regular;
    font-weight: 600;
    font-size: 48px;
    line-height: 65px;
    letter-spacing: -0.03em;
    padding-bottom: 10px;
    color: $st-black-2;

    @include media-breakpoint-down(sm) {
      font-size: 40px;
    }
  }

  .text {
    font-family: $st-font-regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: $st-black-2;
    padding-bottom: 40px;
  }

  .form {
    width: 100%;
    @include setBottomGutters(40px);

    margin-bottom: 25px;
  }

  .link {
    text-align: center;
    font-family: $st-font-regular;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: rgba($st-charcoal, 0.6);
    margin-top: 25px;

    span {
      cursor: pointer;
      text-decoration: none;
      color: $st-primary;
      font-family: $st-font-regular;
      font-weight: 600;

      &:hover {
        opacity: 0.7;
        transition: 0.3s ease;
      }
    }
  }
}

.actions {
 margin-bottom: 25px;
}

.button {
  button {
    width: 100%;
  }
}

%common-text-style {
  font-family: $st-font-regular;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.code {
  padding-top: 15px;
}

.label-code {
  font-family: $st-font-regular;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $st-charcoal;
}

.cta-resend {
  @include flexCentering;

  justify-content: space-between;
  padding: 30px 0 25px;

  &__text {
    @extend %common-text-style;

    color: rgba($st-charcoal, 0.5);
  }

  &__action {
    @extend %common-text-style;

    color: $st-primary;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.6;
      transition: 0.3s ease;
    }
  }
}

.text-code {
  @extend %common-text-style;

  color: rgba($st-charcoal, 0.5);
  padding: 15px 0 40px;

  span {
    color: $st-charcoal;
  }
}

.icon-input {
  i {
    cursor: pointer;
    color: $st-charcoal;
    font-size: 20px;
  }
}
