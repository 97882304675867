/* stylelint-disable */
@import 'abstracts';

@mixin hover-input() {
  background-color: white;
  border-color: rgba($st-stone-3, 0.4);
}

@mixin focus-input() {
  background-color: white;
  border-color: $st-primary;
}

@mixin error-input() {
  background-color: white;
  border-color: $st-red;
  color: $st-red;
}

@mixin default-input() {
  background-color: white;
  border-color: rgba($st-stone-3, 0.2);
  color: $st-stone-3;

  input::placeholder {
    color: rgba($st-stone-3, 0.5);
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

@mixin disabled-input() {
  color: $st-stone-3;
  box-shadow: none;
  cursor: not-allowed;

  input {
    cursor: not-allowed;

    &::placeholder {
      color: rgba($st-stone-3, 0.5);
    }
  }
}

mat-form-field {
  &.mat-mdc-form-field {
    width: 100%;
    font-weight: inherit;
    position: relative;

    &:hover {
      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }
    }

    .mat-mdc-form-field-focus-overlay {
      background: $st-white
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mdc-text-field {
      &--filled:not(.mdc-text-field--disabled) {
        background: transparent;
      }

      &--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: $st-primary;
      }

      &:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: $st-stone-3;
        font-size: 14px;
        font-family: $st-font;
        font-weight: 400;
      }
    }

    .mat-mdc-text-field-wrapper {
      padding: 25px 0 20px 0;

      .mat-mdc-form-field-flex {
        align-items: center;
        border: 1px solid;
        border-radius: 8px;
        box-sizing: border-box;
        font-weight: inherit;
        line-height: 20px;
        min-height: 48px;
        padding: 0;
        transition: all 0.1s;

        .mat-label {
          position: absolute;
          top: 0;
          left: 0;
        }

        @include default-input;

        .mat-mdc-form-field-infix {
          position: unset;
          border: none;
          padding: 0 15px;
          width: 100%;
          min-height: unset;

          app-field-label {
            font-size: 14px;
            font-weight: 600;
            left: 0;
            line-height: 19px;
            position: absolute;
            top: 0;
          }

          mat-select {
            .mat-mdc-select-arrow-wrapper {
              .mat-mdc-select-arrow {
                color: $st-stone-3;
              }
            }
          }

          .error-placeholder {
            bottom: 0;
            left: 0;
            position: absolute;
          }

          textarea {
            margin-bottom: 15px;
            min-height: 40px;
            padding: 15px 0;
          }
        }

        .mat-form-field-suffix,
        .mat-form-field-prefix {
          color: $st-stone-3;
        }

        .mat-form-field-prefix {
          margin-right: -5px;
          padding-left: 10px;
        }

        .mat-form-field-suffix {
          margin-left: -5px;
          padding-right: 10px;
        }

        .mat-mdc-form-field-icon-prefix, [dir=rtl] .mat-mdc-form-field-icon-suffix {
          padding: 0 0 0 10px;
          margin-right: -5px;
        }

        .mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix {
          padding: 0 10px 0 0;
        }
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 2.5px;
      }

      &:hover {
        .mat-mdc-form-field-flex {
          @include hover-input;
        }
      }
    }

    &.mat-form-field-invalid {
      .mat-mdc-form-field-subscript-wrapper {
        display: block;
        position: absolute;
        bottom: -5px;

        .mat-mdc-form-field-hint-wrapper,
        .mat-mdc-form-field-error-wrapper {
          padding: 0;
        }
      }
    }

    &.ng-touched.ng-invalid {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          @include error-input;
        }
      }

      &:hover {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            @include error-input;
          }
        }
      }
    }

    &.mat-form-field-disabled {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          @include disabled-input;
        }
      }

      &:hover {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            @include disabled-input;
          }
        }
      }
    }

    &.mat-focused {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          @include focus-input;
        }
      }

      &:hover {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            @include focus-input;
          }
        }
      }
    }

    &.no-padding {
      .mat-mdc-text-field-wrapper {
        padding: 0;
      }
    }

    &.no-padding-top {
      .mat-mdc-text-field-wrapper {
        padding-top: 0;
      }
    }

    &.no-padding-bottom {
      .mat-mdc-text-field-wrapper {
        padding-bottom: 0;
      }
    }

    .phone-prefix {
      color: rgba($st-stone-3, 0.5);
    }

    .icon-input {
      color: $st-stone-3;
      cursor: pointer;
      font-size: 20px;
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon,
    .mat-mdc-form-field-icon-suffix > .mat-icon {
      padding: 0;
      font-size: 150%;
      line-height: 1.125;
    }
  }

  &.search-input {
    .mat-mdc-text-field-wrapper {
      padding: 12px 0;
    }
  }

  &.mat-field--bigger {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        min-height: 60px;
      }
    }
  }

  .mdc-text-field--disabled {
    &.mdc-text-field--filled {
      background: unset;
    }
  }
}

.ngx-mat-timepicker {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-flex {
          border: unset;

          .mat-mdc-form-field-infix {
            padding: 0;
            border-bottom: 1px solid $st-black-2;
          }
        }
      }
    }
  }
}

.mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field-type-mat-native-select {
  .mat-mdc-form-field-infix {
    &::after {
      right: 26px !important;
    }
  }
}

app-field-label.separate {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 5px;
  color: $st-stone-3;
}
