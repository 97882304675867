@import 'abstracts';

.mat-custom-tabs {
  .mat-mdc-tab {
    &.mdc-tab--active {
      .mdc-tab__text-label {
        font-weight: 600;
        font-family: $st-font-semi-bold;
        color: $st-primary;
      }
    }

    .mdc-tab__text-label {
      padding: 0;
      min-width: fit-content;
      opacity: 1;
      font-size: 14px;
      font-weight: 400;
      font-family: $st-font-regular;
      color: $st-black-2;
    }
  }

  .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #00be91;
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: #00be91;
    --mat-tab-header-active-ripple-color: #00be91;
    --mat-tab-header-inactive-ripple-color: #00be91;
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: #00be91;
    --mat-tab-header-active-hover-label-text-color: #00be91;
    --mat-tab-header-active-focus-indicator-color: #00be91;
    --mat-tab-header-active-hover-indicator-color: #00be91;
  }
}

.mat-tabs-services {
  .mat-mdc-tab-group {
    border-radius: 12px;
    box-shadow: 0 4px 44px 2px rgba($st-charcoal, 0.08);
  }

  .mat-mdc-tab {
    &.mdc-tab--active {
      .mdc-tab__text-label {
        font-weight: 600;
        font-family: $st-font-semi-bold;
      }
    }

    .mdc-tab__text-label {
      font-weight: 400;
      font-family: $st-font-regular;
    }
  }

  .mdc-tab {
    padding: 0;
  }

  .mat-mdc-tab-labels {
    @include setRightGutters(24px);
  }

  .mat-mdc-tab-header {
    --mdc-secondary-navigation-tab-container-height: 56px;
  }

  .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #2d394c;
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: #2d394c;
    --mat-tab-header-active-ripple-color: #2d394c;
    --mat-tab-header-inactive-ripple-color: #2d394c;
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: #2d394c;
    --mat-tab-header-active-hover-label-text-color: #2d394c;
    --mat-tab-header-active-focus-indicator-color: #2d394c;
    --mat-tab-header-active-hover-indicator-color: #2d394c;
  }
}
