@import 'abstracts';

:root {
  --mdc-radio-state-layer-size: 48px;
}

.material-radio {
  .mat-mdc-radio-button {
    .mdc-radio {
      padding: 0;

      .mat-mdc-radio-touch-target {
        @include proportions(20px);
      }

      .mdc-radio__native-control {
        top: unset;
        right: unset;
        left: unset;
        width: unset;
        height: unset;

        &:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
          border: 1px solid rgba($st-charcoal, 0.2);
          background: $st-white;
        }

        &:enabled:checked + .mdc-radio__background {
          .mdc-radio__outer-circle {
            border: unset;
          }

          .mdc-radio__inner-circle {
            transform: scale(1);
            background: $st-primary;
            border: unset;
          }
        }
      }

      &__background {
        &:before {
          background-color: transparent !important;
        }

        .mdc-radio__inner-circle {
          &:before {
            font-family: 'Material Icons';
            content: 'check';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $st-white;
          }
        }
      }
    }
  }

  .mat-radio-checked {
    .mat-radio-inner-circle {
      transform: scale(1);
      background: $st-primary;

      &:before {
        font-family: 'Material Icons';
        content: 'check';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $st-white;
      }
    }
  }

  .mat-radio-ripple {
    display: none;
  }

  .mat-radio-label-content {
    display: none;
  }
}
.st-custom-radio {
  @include column(8px);

  margin-bottom: 16px;

  .st-radio-group-label {
    display: flex;
    align-self: flex-start;
    white-space: break-spaces;

    @include font($st-font-general, 14px, 500, 18.9px, $st-stone-3);
  }

  .mat-mdc-radio-button {
    .mdc-form-field {
      display: flex;
      flex-direction: row-reverse;
      border: 1px solid $st-stone-4;
      border-radius: 8px;

      &:has(.mdc-radio__native-control:checked) {
        border: 1px solid $st-primary;
        background-color: $st-primary-2;
      }

      .mdc-radio,
      .mdc-radio:not(.disabled){
        @include proportions(24px);

        padding: 22px 16px;

        .mdc-radio__native-control:enabled {
          &:checked + .mdc-radio__background {
            .mdc-radio__inner-circle {
              border-color: $st-primary;
              border-width: 16px;
              top: -4px;
              left: -4px;
            }

            .mdc-radio__outer-circle {
              border-color: $st-primary;
            }
          }

          + .mdc-radio__background {
            @include proportions(24px);

            &::before {
              background-color: transparent;
            }

            .mdc-radio__outer-circle {
              border-color: $st-stone-4;
              border-width: 1px;
            }
          }
        }

        .mat-ripple-element {
          display: none;
        }
      }

      .mdc-label {
        padding: 12px 16px ;
        margin-left: 0;
        margin-right: 0;
        cursor: pointer;
        width: 100%;

        @include font($st-font-general, 16px, 500, 26px, $st-stone-3)
      }
    }
  }
}
