//Here should be fonts declarations
@font-face {
  font-family: opensans;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/fonts/open-sans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: opensans regular;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/fonts/open-sans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: opensans medium;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/open-sans/OpenSans-Medium.ttf');
}

@font-face {
  font-family: opensans semibold;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/fonts/open-sans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: opensans bold;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../assets/fonts/open-sans/OpenSans-Bold.ttf');
}


@font-face {
  font-family: 'GeneralSans-Variable';
  src: url('../../assets/fonts/general-sans/GeneralSans-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GeneralSans-VariableItalic';
  src: url('../../assets/fonts/general-sans/GeneralSans-VariableItalic.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Extralight';
  src: url('../../assets/fonts/general-sans/GeneralSans-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-ExtralightItalic';
  src: url('../../assets/fonts/general-sans/GeneralSans-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Light';
  src: url('../../assets/fonts/general-sans/GeneralSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-LightItalic';
  src: url('../../assets/fonts/general-sans/GeneralSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Regular';
  src: url('../../assets/fonts/general-sans/GeneralSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-Italic';
  src: url('../../assets/fonts/general-sans/GeneralSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Medium';
  src: url('../../assets/fonts/general-sans/GeneralSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-MediumItalic';
  src: url('../../assets/fonts/general-sans/GeneralSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Semibold';
  src: url('../../assets/fonts/general-sans/GeneralSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-SemiboldItalic';
  src: url('../../assets/fonts/general-sans/GeneralSans-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Bold';
  src: url('../../assets/fonts/general-sans/GeneralSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-BoldItalic';
  src: url('../../assets/fonts/general-sans/GeneralSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
