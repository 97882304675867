$st-white: #fff;
$st-white-smoke: #f6f6f7;
$st-white-smoke-2: #f2f5f7;
$st-white-smoke-3: #f6f6f6;
$st-white-smoke-4: #f8f8f8;
$st-black: #000;
$st-black-2: #040507;
$st-black-3: #1e1e1e;
$st-black-4: #1f2834;
$st-primary: #00be91;
$st-primary-2: rgba(0, 190, 145, 0.05);
$st-bubbles: #e6f9f4;
$st-medium-torquise: #65cec7;
$st-medium-jungle-green: #27282d;
$st-stone-2: #354052;
$st-stone-3: #2d394c;
$st-stone-4: rgba(45, 57, 76, 0.2);
$st-outer-space: #40454c;
$st-light-slay-gray: #7a8aa3;
$st-light-slay-gray-2: #818894;
$st-soft-gray-2: rgba(170, 167, 178, 0.25);
$st-red: #f03d3e;
$st-red-2: #fc7171;
$st-red-3: #f44336;
$st-blue: #3a87bc;
$st-blue-2: #143fcd;
$st-dodger-blue: #2f8eff;
$st-stone: #7f8fa4;
$st-pastel-blue: #b6c3df;
$st-pink: #ff0084;
$st-manatee: #939999;
$st-platinum: #dde4e4;
$st-platinum-2: #e4e4e4;
$st-platinum-3: #dde2e5;
$st-yellow: #ffc140;
$st-yellow-2: #ffda44;
$st-taupe-gray: #84808e;
$st-gradient: linear-gradient(120deg, #1c6b65 39%, #2c3749 50%);
$st-gradient-2: linear-gradient(rgba(0, 190, 145, 0.16), rgba(0, 190, 145, 0.08));
$st-snow-2: #bbc5d5;
$st-charcoal: #2d394c;
$st-charcoal-2: #334053;
$st-midnight-green: #085559;
$st-gainsboro: #d5d7db;
$st-isabelline: #eaebed;
$st-white-smoke: #f5f7f9;
$st-white-smoke-2: #f3f9f7;
$st-light-gray: #7e8ca4;
$st-gainsbro: #d9d9d9;
$st-gainsbro-2: #d9d9d9;
$st-gainsbro-3: #d9d9d9;

$st-font: opensans;
$st-font-regular: opensans regular;
$st-font-medium: opensans mediuam;
$st-font-semi-bold: opensans semibold;
$st-font-bold: opensans bold;
$st-font-general: GeneralSans;
$st-font-general-sans-regular: GeneralSans-Regular;
$st-font-general-sans-medium: GeneralSans-Medium;
$st-font-general-sans-semibold: GeneralSans-Semibold;
