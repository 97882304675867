@import 'abstracts';

.search-field-input {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-flex {
          border: unset;
          min-height: 60px;
          border-radius: 12px;

          input::placeholder {
            color: rgba($st-charcoal, 0.6);
            font-family: $st-font-semi-bold;
            font-weight: 600;
            font-size: 16px;
          }

          .mat-mdc-form-field-infix {
            padding: 20px 15px 0;
          }
        }
      }
    }

    &.mat-focused {
      &:hover {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            background-color: $st-white-smoke-3;
          }
        }
      }

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
        background-color: $st-white-smoke-3;
      }

      .mat-form-field-label {
        color: $st-charcoal;
      }
    }
  }

  .mat-form-field-label {
    left: 15px;
    color: $st-charcoal;
    font-size: 14px;
    font-family: $st-font-regular;
    font-weight: 400;
    transform: translateY(0.4em) scale(0.75) !important;
  }

  .mat-form-field-label-wrapper {
    position: unset !important;
  }

  .mat-input-element {
    color: $st-charcoal;
    font-family: $st-font-semi-bold;
    font-weight: 600;
    font-size: 16px;
  }
}

.salon-search-input {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          input::placeholder {
            color: $st-charcoal;
          }
        }
      }
    }
  }
}

.mat-contact-input {
  position: relative;

  mat-form-field {
    &.mat-mdc-form-field {
      width: 100%;
      position: relative;

      .mat-mdc-text-field-wrapper {
        padding: 15px 0 20px;

        .mat-mdc-form-field-flex {
          min-height: 60px;
          background: $st-white;
          border: 1px solid rgba($st-charcoal, 0.1);
          border-radius: 8px;

          input::placeholder {
            color: rgba($st-charcoal, 0.5);
            font-family: $st-font-regular;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }

      &.mat-focused {
        .mat-form-field-label {
          color: $st-charcoal;
        }

        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            border-color: $st-primary;
            background: $st-white;
          }
        }
      }

      &.mat-form-field-invalid {
        .mat-mdc-form-field-subscript-wrapper {
          display: block;
          position: absolute;
          bottom: -5px;

          .mat-mdc-form-field-hint-wrapper,
          .mat-mdc-form-field-error-wrapper {
            padding: 0;
          }
        }
      }
    }
  }

  .mat-form-field-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $st-charcoal;
    font-family: $st-font-semi-bold;
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-floating-label {
        top: 18px;
      }
    }

    .mat-mdc-form-field-infix {
      .mat-mdc-floating-label {
        left: 0;
      }
    }
  }
}

.mat-newsletter-input {
  mat-form-field {
    &.mat-mdc-form-field {
      width: 100%;

      .mat-mdc-text-field-wrapper {
        padding: 15px 0 0;

        .mat-mdc-form-field-flex {
          border-radius: 8px 0 0 8px;
        }
      }
    }
  }

  .mat-form-field-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $st-charcoal;
    font-family: $st-font-semi-bold;
  }
}

.mat-contact-textarea {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          min-height: unset;

          textarea::placeholder {
            color: rgba($st-charcoal, 0.5);
            font-family: $st-font-regular;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
  }

  textarea.mat-mdc-input-element {
    min-height: 94px;
    padding: 13px 0;
    resize: none;
  }
}

.sorting-field-input {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-flex {
          border-color: rgba($st-charcoal, 0.1);
          border-radius: 8px;

          input::placeholder {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: rgba($st-charcoal, 0.6);
            font-family: $st-font-semi-bold;
          }
        }
      }

      .mat-mdc-form-field-icon-prefix > .mat-icon,
      .mat-mdc-form-field-icon-suffix > .mat-icon {
        @include flexCentering;
        padding: 0;

        i {
          font-size: 14px;
        }
      }
    }
  }
}
