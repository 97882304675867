@import 'abstracts';

.material-accordion {
  .mat-accordion {
    .mat-expansion-panel {
      border-radius: 8px !important;

      &.mat-expanded {
        box-shadow: unset;

        .mat-expansion-panel-header-title {
          color: $st-white;
        }

        .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
          color: $st-white;
        }
      }
    }

    .mat-expansion-panel-header-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $st-charcoal;
      font-family: $st-font-semi-bold;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }

    .mat-expansion-panel-header {
      height: 60px;

      @include media-breakpoint-down(sm) {
        height: 40px;
      }

      &.mat-expanded {
        background: $st-charcoal;
        height: 60px;
      }
    }

    .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
      color: $st-charcoal;
    }
  }

  .mat-expansion-panel-body {
    padding: 10px 0 0;
  }
}

.mat-accordion-business {
  .mat-accordion {
    @include setBottomGutters(12px);

    .mat-expansion-panel {
      border-radius: 8px;

      &:not(.mat-expanded) {
        .mat-expansion-panel-header {
          &:not([aria-disabled=true]):hover {
            background: linear-gradient(91deg, $st-charcoal 28.92%, rgba($st-primary, 0.30) 157.71%), $st-charcoal;
          }
        }
      }

      &:not(.mat-expanded),
      &:not(.mat-expansion-panel-spacing) {
        border-radius: 8px;
      }

      &:first-of-type {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      &:last-of-type {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &.mat-expanded {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: unset;

        .mat-expansion-indicator {
          &::after {
            content: "\e924";
          }
        }
      }

      .mat-expansion-indicator {
        &::after {
          content: "\e925";
          font-family: 'icomoon';
          border-style: unset;
          border-width: unset;
          display: inline-block;
          padding: 0;
          transform: unset;
          vertical-align: middle;
          color: $st-white;
          font-size: 24px;
        }
      }

      .mat-expansion-panel-header {
        background: linear-gradient(91deg, $st-charcoal 28.92%, rgba($st-primary, 0.30) 157.71%), $st-charcoal;
        height: 56px;

        &.mat-expanded {
          height: 64px;

          &:focus,
          &:hover {
            background: linear-gradient(91deg, $st-charcoal 28.92%, rgba($st-primary, 0.30) 157.71%), $st-charcoal;
          }
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 24px;
      border-color: $st-primary;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-radius: 0 0 8px 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      font-family: $st-font-general-sans-regular, $st-font;
      color: $st-black-2;
    }
  }
}
