@import 'variables';

@mixin font($font-family, $font-size, $font-weight, $line-height, $color) {
  font-family: $font-family, sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
}

@mixin column($gap: 0) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin setRightGutters($value) {
  & > * {
    margin-right: $value;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin blocktify() {
  display: block;
  width: 100%;
}

@mixin setBottomGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-bottom: $value;

    &:last-child {
      margin-bottom: $lastGutter;
    }
  }
}

@mixin setTopGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-top: $value;

    &:last-child {
      margin-top: $lastGutter;
    }
  }
}

@mixin setBottomMargin($value) {
  margin-bottom: $value;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin setLeftGutters($value) {
  & > * {
    margin-left: $value;

    &:first-child {
      margin-left: 0;
    }
  }
}

@mixin proportions($size) {
  width: $size;
  height: $size;
}

@mixin setProportions($width, $height) {
  width: $width;
  height: $height;
}

@mixin setObjectFit($type) {
  width: 100%;
  height: 100%;
  object-fit: $type;
}

@mixin setAbsoluteCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  left: $size;
  transform: translate(-$size, -$size);
}

@mixin setAbsoluteVerticalCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  transform: translateY(-$size);
}

@mixin setAbsoluteHorizontalCenter() {
  $size: 50%;
  position: absolute;
  left: $size;
  transform: translateX(-$size);
}

@mixin setFontAwesomeFreeIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Free';
  font-weight: $weight;
  content: $code;
}

@mixin setFontAwesomeProIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Pro';
  font-weight: $weight;
  content: $code;
}

@mixin straightTop() {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@mixin straightBottom() {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@mixin sectionDivider($vertical-margins, $color) {
  margin: $vertical-margins 0;
  padding: $vertical-margins 0;
  border-bottom: 1px solid $color;

  &:last-of-type {
    border: none;
    margin: 0;
  }
}

@mixin sectionDividerRight($color) {
  border-right: 1px solid $color;

  &:last-of-type {
    border: none;
    margin: 0;
  }
}

@mixin card-layout($padding, $radius: 3px) {
  padding: $padding;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: 0 2px 4px 1px rgba(202, 202, 202, 0.5);
}

@mixin setColGutters($gutters) {
  & > * {
    padding: $gutters;
  }
}

@mixin flexCentering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin setPlaceholderStyles($color: #c6c4ce, $font-weight: normal, $font-size: 13px) {
  /* stylelint-disable */
  &:-ms-input-placeholder {
    color: $color;
    font-weight: $font-weight;
    font-size: $font-size;
    font-family: $st-font;
  }

  &::-webkit-input-placeholder {
    color: $color;
    font-weight: $font-weight;
    font-size: $font-size;
  }

  &::placeholder {
    color: $color;
    font-weight: $font-weight;
    font-size: $font-size;
  }
}

@mixin truncateAndDots($maxWidth) {
  max-width: $maxWidth;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin green-scrollbar() {
  /* The emerging W3C standard
     that is currently Firefox-only */
  * {
    scrollbar-width: thin;
    scrollbar-color: $st-primary;
  }

  /* Works on Chrome/Edge/Safari */

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $st-manatee;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $st-primary;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba($st-primary, 0.8);
  }
}

@mixin textEllipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /* stylelint-disable-line */
  -webkit-box-orient: vertical; /* stylelint-disable-line */
}

@mixin scrollbar($width, $scrollbar-color, $scrollbar-color-track, $border-radius) {
  /* The emerging W3C standard
     that is currently Firefox-only */
  * {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-color;
  }

  /* Works on Chrome/Edge/Safari */

  /* width */
  ::-webkit-scrollbar {
    width: $width;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba($st-charcoal, 0.3);
    border-radius: $border-radius;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $scrollbar-color;
    border-radius: $border-radius;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-color-track;
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
