@import 'abstracts';

.mat-custom-checkbox {
  .mdc-checkbox {
    @include proportions(18px);
    padding: unset;
    margin: 0 10px 0 0;
    transform: translateY(5px);

    .mdc-checkbox__native-control {
      top: unset;
      right: unset;
      left: unset;
      width: unset;
      height: unset;
    }

    .mdc-checkbox__background {
      top: unset;
      left: unset;
    }

    .mdc-checkbox__native-control {
      &:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
        border: 1px solid $st-charcoal;
        border-radius: 4px;
      }
    }

    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
      background: $st-primary;
      border-color: $st-primary;
    }

    &:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    &:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    &:not(:disabled):active .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
      background: $st-primary;
      border-color: $st-primary;
    }

    &:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    &:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    &:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
      background-color: $st-primary;
      border-color: $st-primary;
    }

    .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
      background-color: $st-primary;
      border-color: $st-primary;
    }
  }

  .mdc-form-field {
    display: flex;
    align-items: baseline;
  }

  .mat-mdc-checkbox-touch-target {
    @include proportions(18px);
  }

  .mdc-checkbox__ripple {
    background-color: transparent !important;
  }

  .mdc-label {
    font-family: $st-font-regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $st-charcoal;
  }

  .mdc-checkbox__ripple {
    background-color: transparent !important;
  }
}
