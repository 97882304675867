@import 'abstracts';

.mat-form-field-appearance-fill div.mat-select-arrow-wrapper {
  transform: none;
}


.search-field-select {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-flex {
          border: unset;
          min-height: 60px;
          border-radius: 12px;

          .mat-mdc-form-field-infix {
            padding: 20px 15px 0;
          }
        }
      }
    }

    &.mat-focused {
      &:hover {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            background-color: $st-white-smoke-3;
          }
        }
      }

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
        background-color: $st-white-smoke-3;
      }

      .mdc-floating-label {
        color: $st-charcoal;
      }
    }
  }

  .mat-mdc-select-placeholder {
    color: rgba($st-charcoal, 0.6);
    font-family: $st-font-semi-bold;
    font-weight: 600;
  }

  .mat-form-field-label-wrapper {
    position: unset !important;
  }

  .mat-select-value {
    color: $st-charcoal;
    font-family: $st-font-semi-bold;
    font-weight: 600;
    font-size: 16px;
  }
}

.salon-search-select {
  .mat-mdc-select-placeholder {
    color: $st-charcoal;
  }
}

.phone-search-input-option {
  mat-form-field.mat-mdc-form-field {
    width: 100%;

    .mat-mdc-text-field-wrapper {
      padding: 0;
    }
  }
}

.sorting-field-select {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-flex {
          border: 1px solid rgba(45, 57, 76, 0.1);
          border-radius: 8px;

          .mat-mdc-form-field-infix {
            mat-select {
              .mat-mdc-select-arrow-wrapper {
                .mat-mdc-select-arrow {
                  color: $st-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mat-primary {
  .mat-mdc-option {
    padding: 0 20px;

    .mdc-list-item__primary-text {
      font-weight: 600;
      font-family: $st-font;
      color: $st-charcoal;
    }

    &.mdc-list-item--selected:not(.mdc-list-item--disabled) {
      .mdc-list-item__primary-text {
        color: $st-primary;
      }
    }
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: $st-primary;
    @include flexCentering;
    border-color: transparent !important;
  }


  .mat-mdc-option {
    .mat-pseudo-checkbox-minimal {
      display: none;
    }
  }
}

.mat-pseudo-checkbox {
  width: 28px !important;
  height: 28px !important;
  border-radius: 4px !important;;
  border: 1px solid rgba(45, 57, 76, 0.3) !important;;
}

.mat-pseudo-checkbox::after {
  position: unset !important;
}

.mat-option-text {
  color: $st-charcoal;
  font-family: $st-font-semi-bold;
  font-weight: 600;
}

.dropdown-language {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 0;

        .mat-mdc-form-field-flex {
          min-height: unset;
          border: unset;
          background: transparent;

          .mat-mdc-form-field-infix {
            padding: 0;

            mat-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
              display: none;
            }
          }
        }
      }
    }

    &.mat-focused {
      &:hover {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            background-color: transparent;
          }
        }
      }

      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          background-color: transparent;
        }
      }

      .mdc-floating-label {
        color: $st-charcoal;
      }
    }
  }
}

.mobile-select {
  mat-form-field {
    &.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        padding: 20px 0 0;

        .mat-mdc-form-field-flex {
          border: 1px solid rgba($st-charcoal, 0.2);
          border-radius: 6px;

          .mat-mdc-form-field-infix {
            mat-select {
              .mat-select-arrow-wrapper {
                .mat-select-arrow {
                  color: $st-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}

.native-select {
  mat-form-field {
    width: 100%;
  }

  .mat-form-field-type-mat-native-select {
    .mat-mdc-form-field-infix {
      &::after {
        border: none;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f107";
        right: unset;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .mat-select-panel {
    min-width: 100% !important;
  }
}
