@import 'abstracts';

.material-button {
  .mdc-button {
    min-height: 60px;
    background: $st-primary;
    min-width: unset;
    font-weight: 700;
    font-size: 16px;
    font-family: $st-font-bold;
    border-radius: 12px;
    padding: 0 20px;
    letter-spacing: normal;
    white-space: nowrap;

    &:not(:disabled) {
      color: $st-white;
    }

    &[disabled] {
      background: #ccc;
      color: $st-charcoal;
      opacity: 0.5;
      cursor: not-allowed;
    }

    .mdc-button__label {
      @include flexCentering;
      @include setRightGutters(11px);
    }

    .mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
      stroke: $st-white;
    }

    &.spinner-button {
      .mdc-button__label {
        align-items: center;
        display: flex;
        justify-content: center;

        @include setRightGutters(5px);
      }
    }

    &.mat-mdc-button:has(.material-icons,mat-icon,[matButtonIcon]) {
      padding: 0 20px;
    }
  }

  .mat-newsletter-primary {
    width: 100%;
    border-radius: 0 8px 8px 0;

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }

  .mat-button-second {
    background: $st-charcoal;
    padding: 0 40px;

    &-transparent {
      background: rgba($st-black-2, 0.05);

      &:not(:disabled) {
        color: $st-black-2
      }
    }

    &-small {
      min-height: 44px;
      padding: 0 20px;
    }

    @include media-breakpoint-down(md) {
      min-height: 40px;
      padding: 0 20px;
    }
  }

  .mat-white {
    background: $st-white;

    &.mdc-button:not(:disabled) {
      color: $st-primary;
    }

    &:hover {
      background: none;
      border: none;
      text-decoration: underline;
    }
  }

  .mat-button-primary-small {
    min-height: 40px;
    font-size: 14px;
    line-height: 19px;
    border-radius: 8px;

    &.mat-button-icon {
      .mat-icon {
        @include flexCentering;
      }

      .mat-icon {
        i {
          font-size: 9px;
          margin-right: 3px;
        }
      }

      .next-icon {
        i {
          font-size: 9px;
        }
      }
    }
  }

  .mat-white-small {
    background: $st-white;
    border: 1px solid rgba(45, 57, 76, 0.1);
    border-radius: 8px;
    min-height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    font-family: $st-font-semi-bold;

    &.mdc-button:not(:disabled) {
      color: $st-charcoal;
    }

    &.mat-button-icon {
      .mdc-button__label,
      .mat-icon {
        @include flexCentering;
      }

      .mat-icon {
        i {
          font-size: 9px;
          margin-right: 3px;
        }
      }
    }
  }

  .mat-gradient-button {
    background: $st-gradient-2;
    border-radius: 8px;
    padding: 15px 30px;
    cursor: pointer;
    border: 1px solid $st-primary;

    @include font($st-font-general, 18px, 500, 24px, $st-white);

    &:hover {
      transition: all ease-in-out .5ms;
      background: $st-primary;
    }
  }

  .mat-white-big {
    background: $st-white;
    border: 1px solid rgba(45, 57, 76, 0.1);
    border-radius: 8px;
    min-height: 60px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-family: $st-font-bold;

    &.mdc-button:not(:disabled) {
      color: $st-charcoal;
    }
  }

  .mat-button-icon-reservation {
    min-height: 40px;
    width: 40px;
    padding: 0;
    font-size: 14px;
    border-radius: 8px;

    &-back {
      border: 1px solid rgba($st-charcoal, 0.2);
      background: $st-white;

      i {
        color: $st-charcoal;
      }
    }
  }

  .mat-button-primary-transparent {
    min-height: 40px;
    background: $st-white;
    min-width: unset;
    font-weight: 600;
    font-size: 16px;
    font-family: $st-font-bold;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid $st-primary;

    &.mdc-button:not(:disabled) {
      color: $st-primary;
    }

    &__rounted {
      background: transparent;
      border-radius: 48px;
      border-color: rgba($st-primary, 0.2);
      min-height: 54px;
    }
  }

  .mat-button-danger {
    background: $st-white;
    border: 1px solid $st-red-2;
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    font-family: $st-font-semi-bold;

    &.mdc-button:not(:disabled) {
      color: $st-red-2;
    }
  }
}

.material-button-icon {
  .mat-icon-button {
    background: $st-primary;
    @include proportions(60px);
    border-radius: 12px;

    i {
      color: #fafafa;
      font-size: 22px;
      line-height: 22px;
    }
  }

  .mat-icon {
    height: unset;
    width: unset;
    @include flexCentering;
  }
}

.mat-button-fixed {
  .mdc-button__label {
    @include flexCentering;
    @include setRightGutters(11px);

    .image-filter {
      transform: translateY(1px);
    }
  }
}

.mat-button-disabled {
  opacity: 0.5;
}
