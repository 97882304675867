@import 'abstracts';

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-bar {
      background: rgba($st-primary, 0.54);
    }

    .mat-slide-toggle-thumb {
      background: $st-primary;
    }

    .mat-ripple-element {
      background: $st-primary;
    }
  }

  .mat-slide-toggle-content {
    color: $st-black-2;
  }
}

.toggle {
  &__description {
    padding-left: 45px;
    font-size: 11px;
    max-width: 500px;
    color: rgba($st-charcoal, 0.5);
    font-family: $st-font-regular;
    line-height: 14px;
  }
}

.material-slide {
  .mdc-switch__shadow {
    background: $st-primary;
  }

  .mdc-switch:enabled .mdc-switch__track::after {
    background: $st-primary !important;
  }

  .mat-slide-toggle-bar {
    height: 20px;
    border-radius: 27px;
    background: $st-white;
    border: 1px solid rgba($st-charcoal, 0.2);
  }

  .mat-slide-toggle-thumb {
    @include proportions(16px);

    background: rgba($st-charcoal, 0.3);
  }

  .mat-slide-toggle-thumb-container {
    @include proportions(16px);

    top: 0.6px;
    left: 2px;
  }

  .mat-slide-toggle {
    &.mat-checked {
      .mat-slide-toggle-thumb {
        background: $st-white;
      }

      .mat-slide-toggle-bar {
        border-color: transparent;
        background: $st-primary;
      }
    }
  }
}
