@use "abstracts/variables" as variables;
@use "abstracts/mixins" as mixins;

$text-alignments: (center, left, right);

@each $alignment in $text-alignments {
  .st-text-#{$alignment} {
    text-align: $alignment;
  }
}

@for $i from 1 through 10 {
  $step: calc($i * 2);

  .st-gap-#{$i} {
    gap: #{$step}px;
  }
}

.st-text-white {
  color: variables.$st-white;
}

.st-text-primary {
  color: variables.$st-primary;
}

.st-link {
  text-decoration: underline;

  @include mixins.font(variables.$st-font-general, 14px, 500, 18px, variables.$st-stone-3)
}
