@import 'abstracts';

.mat-mdc-card {
  &.inline-calendar-card {
    box-shadow: unset;
    border: 1px solid rgba($st-charcoal, 0.2);
    border-radius: 8px;

    .mat-calendar-header {
      padding: 0;

      .mat-calendar-controls {
        margin: 0 0 24px;
        padding: 16px 24px;
        border-bottom: 1px solid $st-gainsbro;
      }
    }

    .mat-calendar-table-header-divider {
      display: none;
    }

    .mat-calendar-body-cell-content {
      @include media-breakpoint-up(md) {
        height: 64px;
      }

      border-radius: 8px;

      &.mat-calendar-body-selected {
        background: $st-primary;
      }
    }

    .mat-calendar-body-cell-container {
      @include media-breakpoint-up(md) {
        padding: 0 !important;
        height: 76px;
      }
    }

    .mat-calendar-body-cell {
      @include media-breakpoint-up(lg) {
        height: 63px;
      }
    }

    .mat-calendar-body-label {
      visibility: hidden;
      @include media-breakpoint-up(md) {
        padding: 0 48px !important;
      }
    }

    .mat-calendar-body-cell-content {
      background: rgba($st-primary, 0.1);
    }

    .mat-calendar-body-disabled {
      .mat-calendar-body-cell-content {
        background: transparent;
      }
    }

    .closed,
    .unavailable {
      opacity: 0.3;

      .mat-calendar-body-cell-content {
        background: transparent;
      }

      .calendar-day-content {
        @include setAbsoluteHorizontalCenter();
        font-size: 10px;
        font-weight: 400;
        font-family: $st-font;
        bottom: 2px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .mat-calendar-body-today {
      border-color: rgba($st-primary, 1);
      color: $st-black;
    }

    .few {
      .mat-calendar-body-cell-content {
        background: rgba($st-yellow, 0.1);
        border-color: $st-yellow;

        &.mat-calendar-body-selected {
          background: $st-primary;
        }
      }
    }
  }
}
