@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon/icomoon.eot');
  src: url('../../assets/fonts/icomoon/icomoon.eot') format('embedded-opentype'),
  url('../../assets/fonts/icomoon/icomoon.ttf') format('truetype'),
  url('../../assets/fonts/icomoon/icomoon.woff') format('woff'),
  url('../../assets/fonts/icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'], [class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play-transparent:before {
  content: '\e932';
  color: #fff;
}

.icon-arrow-up-round:before {
  content: '\e930';
}

.icon-arrow-down-round:before {
  content: '\e931';
}

.icon-clock:before {
  content: '\e92f';
}

.icon-no-gallery:before {
  content: '\e92d';
  color: #7e8ca4;
}

.icon-business-page:before {
  content: '\e900';
}

.icon-calendar-edit:before {
  content: '\e901';
}

.icon-calendar-number:before {
  content: '\e902';
}

.icon-calendar-time:before {
  content: '\e903';
}

.icon-user-octagon:before {
  content: '\e904';
}

.icon-notification:before {
  content: '\e905';
}

.icon-close:before {
  content: '\e906';
}

.icon-hamburger:before {
  content: '\e907';
}

.icon-search:before {
  content: '\e908';
}

.icon-star:before {
  content: '\e909';
}

.icon-arrow-next:before {
  content: '\e90a';
}

.icon-arrow-prev:before {
  content: '\e90b';
}

.icon-arrow-next-big:before {
  content: '\e90c';
}

.icon-arrow-prev-big:before {
  content: '\e90d';
}

.icon-calendar:before {
  content: '\e90e';
}

.icon-grid:before {
  content: '\e90f';
}

.icon-list:before {
  content: '\e910';
}

.icon-card:before {
  content: '\e911';
}

.icon-cash:before {
  content: '\e912';
}

.icon-location:before {
  content: '\e913';
}

.icon-location-bold:before {
  content: '\e914';
}

.icon-union:before {
  content: '\e915';
}

.icon-images:before {
  content: '\e916';
}

.icon-eye-show:before {
  content: '\e917';
}

.icon-hands-clapping:before {
  content: '\e918';
}

.icon-phone:before {
  content: '\e919';
}

.icon-document-transparent:before {
  content: '\e91a';
}

.icon-female:before {
  content: '\e91b';
}

.icon-male:before {
  content: '\e91c';
}

.icon-calendar-outline:before {
  content: '\e91d';
}

.icon-globe:before {
  content: '\e91e';
}

.icon-pentagon:before {
  content: '\e91f';
}

.icon-masters:before {
  content: '\e920';
}

.icon-calendar-transparent:before {
  content: '\e921';
}

.icon-plus:before {
  content: '\e922';
}

.icon-success:before {
  content: '\e923';
}

.icon-minus-square:before {
  content: '\e924';
}

.icon-plus-square:before {
  content: '\e925';
}

.icon-play-circle:before {
  content: '\e926';
}

.icon-code-circle:before {
  content: '\e927';
}

.icon-message-question:before {
  content: '\e928';
}

.icon-onboarding:before {
  content: '\e929';
}

.icon-airplane-vacation:before {
  content: '\e92a';
}

.icon-business-profile:before {
  content: '\e92b';
}

.icon-clients:before {
  content: '\e92c';
}

.icon-specialists:before {
  content: '\e92e';
}
